@import "base/helpers";

@include component(radio-toggle) {
  // Variables ----------------------
  $clr_brandPrimary: #1fa8df !default;
  $font-family-primary: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif !default;
  $inputs_borderColor: lighten($clr_textSubdued, 30%) !default;
  $radio-toggle-border-radius: 0.5rem;

  // Component ----------------------
  font-family: $font-family-primary;
  line-height: 1;

  // Parts ----------------------
  @include part(options) {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }

  @include part(option) {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative; // Keep hidden input field within label, to prevent page scrolling up
    width: 100%;

    &:first-child {
      .radio-toggle__toggle {
        border-bottom-left-radius: $radio-toggle-border-radius;
        border-top-left-radius: $radio-toggle-border-radius;
      }
    }

    &:last-child {
      .radio-toggle__toggle {
        border-bottom-right-radius: $radio-toggle-border-radius;
        border-top-right-radius: $radio-toggle-border-radius;
      }
    }

    &:hover {
      cursor: pointer;
    }

    @include option(disabled) {
      cursor: not-allowed;
      opacity: 70%;
    }
  }

  @include part(toggle) {
    align-items: center;
    background-color: #fff;
    border: 1px solid $inputs_borderColor;
    display: flex;
    font-weight: 700;
    height: 100%;
    justify-content: center;
    padding: 0.75rem 0.25rem;
    text-align: center;
    width: 100%;

    .radio-toggle__option-input:checked ~ & {
      background-color: $clr_brandPrimary;
      border-color: $clr_brandPrimary;
      color: #fff;
    }
  }

  @include part(option-input) {
    @include visuallyHidden;

    top: 0; // So FE validation scrolls to the right spot
  }

  // Options ----------------------
  @include option(small) {
    @include part(toggle) {
      @include typography-size-7;

      padding: 0.75rem 0.5rem;
    }
  }
}
