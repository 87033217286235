@import "base/helpers";

@include component(fundraiser-dropdown) {
  @include part(label) {
    display: flex;
  }

  @include part(name) {
    margin: 0 0 0 3px;
  }

  @include part(image) {
    border-radius: 30px;
  }

  @include part(avatar-container) {
    height: 24px;
    width: 24px;
  }
}
