@import "base/helpers";

@include component(optional-selector) {
  // Variables ----------------------
  $clr_brandPrimary: #1fa8df !default;
  $font-family-primary: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif !default;
  $inputs_borderColor: lighten($clr_textSubdued, 30%) !default;
  $inputs_borderRadius: 4px !default;

  // Component ----------------------
  background-color: lighten($clr_brandPrimary, 45%);
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 20px;

  // Parts ----------------------
  @include part(select) {
    background-color: #fff;
    border: 1px solid $inputs_borderColor;
    border-radius: $inputs_borderRadius;
    font-family: $font-family-primary;
    font-size: 1rem;
    padding: 0.25rem 0.5rem;
    width: 100%;
  }

  @include part(line-item) {
    align-items: flex-start;
    display: flex;
    font-family: $font-family-primary;
    justify-content: space-between;
    line-height: 1.2;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  @include part(line-item-label) {
    display: block;
    padding-right: 1rem;
  }

  @include part(line-item-value) {
    @include typography-bold;

    display: block;
  }

  @include part(input-other) {
    font-family: $font-family-primary;
    margin-top: 0.5rem;
  }

  @include part(about) {
    align-items: flex-start;
    display: flex;
    font-family: $font-family-primary;
    margin-top: 1rem;
  }

  @include part(about-media) {
    display: block;
    font-size: 3rem;
    flex: 0 1 auto;
    padding-right: 1rem;
  }

  @include part(about-copy) {
    display: block;
    flex: 1 1 auto;
    font-size: 0.875rem;
    line-height: 1.4;
  }
}
