@import "base/helpers";

.grecaptcha-badge {
  visibility: hidden !important;
}

.turnstile__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  &.hidden {
    display: none;
  }
}

@include component(donation) {
  // Variables ----------------------
  $clr_brandPrimary: #1fa8df !default;
  $clr_text: #333 !default;
  $clr_textSubdued: lighten($clr_text, 30%) !default;
  $inputs_borderColor: lighten($clr_textSubdued, 30%) !default;
}

@include component(donation-form) {
  // Variables ----------------------
  $font-family-primary: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif !default;
  $clr_error: #d60000 !default;

  // Component ----------------------
  font-family: $font-family-primary;

  // Parts ----------------------
  @include part(fieldset) {
    &:not(:last-child) {
      margin-bottom: 2rem;

      @include breakpoint(tablet) {
        margin-bottom: 4rem;
      }
    }
  }

  @include part(selector-hide) {
    display: none;
  }

  @include part(fundraiser-dropdown) {
    &.payment__header {
      margin-bottom: 1rem;
    }
  }

  @include part(custom-fields) {
    padding-top: 1rem;
  }

  @include part(heading) {
    align-items: center;
    border-bottom: 1px solid #333;
    margin-bottom: 1rem;
    padding-bottom: 0.25rem;

    @include option(details) {
      margin-top: 2rem;

      @include breakpoint(tablet) {
        margin-top: 4rem;
      }
    }

    @include option(payment) {
      margin-top: 4rem;
    }
  }

  @include part(matched-giving) {
    margin-bottom: 1rem;

    .option-selector__line-item-title {
      font-size: 1.2rem;
      font-weight: bold;
    }

    &-amount {
      &.donation-form__matched-giving-amount-copy {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  @include part(line-item) {
    align-items: flex-start;
    display: flex;
    font-family: $font-family-primary;
    justify-content: space-between;
    line-height: 1.2;
    margin-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    > .input-row {
      @include option(select) {
        margin: 0;
        width: 100%;

        label.select {
          width: 100%;

          select {
            border-radius: 0.5rem;
          }
        }
      }
    }

    > .donation-form__options {
      width: 100%;
    }

    @include option(total) {
      border-top: 1px solid #f1f3f5;
      padding-top: 15px;

      @include part(line-item-value) {
        text-align: right;
      }
    }

    @include option(donation) {
      border-top: 1px solid #f1f3f5;
      padding-top: 15px;
    }

    &--currency-change {
      cursor: pointer;
    }
  }

  @include part(perk-container) {
    background: #fff;
    margin: 0 -1rem;
    padding: 0 0 1rem;
    position: relative;

    @include option(arrow-box) {
      border: 1px solid $inputs_borderColor;
      border-radius: 0.5rem;
      margin: 0;
      padding: 0 0 1rem;
      position: relative;

      &::after,
      &::before {
        bottom: 100%;
        left: 2rem;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }

      &::after {
        border-color: rgba(255, 255, 255, 0%);
        border-bottom-color: #fff;
        border-width: 12px;
        margin-left: -12px;
      }

      &::before {
        border-color: rgba(217, 217, 217, 0%);
        border-bottom-color: $inputs_borderColor;
        border-width: 13px;
        margin-left: -13px;
      }
    }
  }

  @include part(options) {
    display: flex;

    > .input-row {
      @include option(select) {
        margin: 0;
        width: 100%;

        label.select {
          width: 100%;
        }
      }
    }

    label.select {
      border-radius: 0 0.5rem 0.5rem 0;
      display: inline-block;
      width: 28%;

      select {
        border-radius: 0 0.5rem 0.5rem 0;
        border-left: none;
        color: $clr_text;
        font-weight: 700;
        padding-left: 43%;

        option {
          color: $clr_text;
          font-weight: 700;

          &:disabled {
            color: $clr_textSubdued;
            background: $inputs_borderColor;
          }
        }
      }

      @include option(active) {
        background: $clr_brandPrimary;

        select {
          color: white;

          option {
            color: $clr_text;
          }
        }

        &::after {
          color: white;
        }
      }

      @include option(disabled) {
        background: $inputs_borderColor;
        color: $clr_textSubdued;
        cursor: not-allowed;

        select {
          color: $clr_textSubdued;
          cursor: not-allowed;
        }
      }
    }
  }

  @include part(option) {
    border: 1px solid #ccc;
    border-left: none;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    padding: 0.75rem 0.25rem;
    text-align: center;
    width: 18%;

    &:first-child {
      border-radius: 0.5rem 0 0 0.5rem;
      border-left: 1px solid #ccc;
    }

    @include option(active) {
      background: $clr_brandPrimary;
      color: white;
    }

    @include option(disabled) {
      background: $inputs_borderColor;
      border-right-color: #ababab;
      color: $clr_textSubdued;
      cursor: not-allowed;
    }
  }

  @include part(option-input) {
    display: none;
  }

  @include part(perk-question) {
    display: block;
    font-weight: 700;
    margin-bottom: 0.25em;
    width: 100%;
  }

  @include part(only-available) {
    font-size: 0.75rem;
    padding-left: 0.5rem;
  }

  @include part(line-item-label) {
    display: block;
    padding-right: 1rem;

    &.--change-currency {
      font-size: 14px;

      &.donation-form__currency-conversion-platform-fee {
        padding-top: 10px;
      }
    }
  }

  @include part(line-item-value) {
    @include typography-bold;

    display: block;
  }

  @include part(line-item-currency) {
    font-weight: 400;
    padding-right: 0.5rem;
  }

  @include part(line-item-total) {
    font-size: 2rem;
  }

  @include part(line-item-suffix) {
    font-weight: 400;
    padding-left: 0.5rem;
  }

  @include part(line-info) {
    font-size: 0.875rem;
    font-style: italic;
    margin-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @include part(regular-giving) {
    margin-top: 1rem;
  }

  @include part(payment-method) {
    position: relative;
    margin-bottom: 1rem;

    &--direct-debit {
      margin-top: 25px;
    }
  }

  @include part(payment-instruction) {
    @include typography-base;

    font-size: 1rem;
    margin-top: 1rem;
  }

  @include part(input-card) {
    background-color: #fff;
    border: 1px solid $inputs_borderColor;
    border-radius: 4px;
    padding: 0.75rem 1rem;

    @include option(invalid) {
      border-color: red;
    }
  }

  @include part(input-card-expiry-cvc) {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include breakpoint(phablet) {
      flex-direction: row;

      label {
        flex: 1;

        &:nth-of-type(1) {
          padding-right: 0.5rem;
        }
      }
    }
  }

  @include part(terms) {
    @include typography-base;

    font-size: 0.875rem;
    margin-bottom: 1rem;
  }

  @include part(campaigner-disclaimer) {
    @include typography-base;

    background-color: lighten($clr_brandPrimary, 45%);
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    padding: 1rem;
  }

  @include part(action) {
    margin-top: 1rem;

    @include breakpoint(tablet) {
      margin-top: 2rem;
      max-width: 80%;
    }

    @include breakpoint(laptop) {
      max-width: 60%;
    }
  }

  @include part(error) {
    @include typography-base;

    color: $clr_error;
    font-size: 0.875rem;
    margin-top: 1rem;
  }

  @include part(card-error) {
    color: $clr_error;
  }

  @include part(submission-error) {
    color: $clr_error;
  }
}

@include component(donation-fee-confirmation) {
  // Variables ----------------------
  $clr_brandPrimary: #1fa8df !default;
  $clr_text: #333 !default;
  $clr_textSubdued: lighten($clr_text, 30%) !default;
  $inputs_borderColor: lighten($clr_textSubdued, 30%) !default;

  // Component ----------------------
  background-color: lighten($clr_brandPrimary, 45%);
  padding: 1rem;

  // Parts ----------------------
  @include part(line-item) {
    align-items: flex-start;
    display: flex;
    font-family: $font-family-primary;
    justify-content: space-between;
    line-height: 1.2;
    margin-top: 1rem;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    @include option(total) {
      @include part(line-item-value) {
        text-align: right;
      }
    }
  }

  @include part(line-item-label) {
    display: block;
    padding-right: 1rem;
  }

  @include part(line-item-value) {
    @include typography-bold;

    display: block;
  }

  @include part(line-item-currency) {
    font-weight: 400;
    padding-right: 0.5rem;
  }

  @include part(line-item-total) {
    font-size: 2rem;
  }

  @include part(line-item-suffix) {
    font-weight: 400;
    padding-left: 0.5rem;
  }
}
