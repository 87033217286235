@import "base/helpers";

@include component(donation-total) {
  @include part(container) {
    display: flex;
    justify-content: space-between;
  }

  @include part(change-currency) {
    display: flex;
    flex-direction: row;
  }
}
