@import "base/helpers";
@import "base/index";
@import "components/Avatar/_style";
@import "components/Header/_style";
@import "components/LinkButton/_style";
@import "components/Navigation/_style";
@import "components/Footer/_style";
@import "components/CampaignCard/_style";
@import "components/CampaignListing/_style";
@import "components/PageBlock/_style";
@import "components/Payment/_style";
@import "components/TextHeading/_style";

$clr_text: #333 !default;
$font-family-primary: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif !default;
$campaign-card-image-background-color: #eee !default;

.heading-status {
  text-align: right;
  font-family: $font-family-primary;

  .draft-mode {
    border: 3px solid #28ab60;
    color: #28ab60;
    display: inline-block;
    font-weight: 900;
    margin: 20px 0;
    padding: 5px 10px;
  }
}

@include component(payment) {
  @include part(form-only) {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  @include part(body) {
    &.payment__body-form-only {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  @include part(header) {
    border-bottom: none;
    padding-bottom: 0.5rem;

    > .text-heading {
      display: block;
      border-bottom: 1px solid #ccc;
      padding-bottom: 0.5rem;
      position: relative;
      margin-bottom: 0.75rem;
    }

    >label.select {
      @include breakpoint(phablet) {
        margin-bottom: 2rem;
      }
    }
  }

  @include part(campaign-mobile-title) {
    font-weight: bold;
    color: $clr_text;

    @include breakpoint(phablet) {
      display: none;
    }
  }

  @include part(campaign) {
    @include part(card) {
      margin-top: 2rem;
    }
  }

  select {
    position: relative;
    font-family: $font-family-primary;
  }

  @include part(fundraiser-profile-image) {
    background: white;
    border: solid white 2px;
    border-radius: 2px;
    bottom: -0.5rem;
    display: inline-block;
    left: 0.5rem;
    position: absolute;
    width: 5rem;
  }

  @include part(image-tag) {
    opacity: 0%;
    position: absolute;
    height: 100%;
    width: 100%;
  }

  @include part(image) {
    background-color: $campaign-card-image-background-color;
    background-position: center;
    background-size: cover;
    display: block;
    height: 0;
    overflow: visible;
    padding-bottom: 56.25%; // 16:9 ratio
    position: relative;
    width: 100%;

    .campaign-card:hover & {
      transform: scale(1.05);
    }
  }
}
