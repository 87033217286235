@import "base/helpers";

@include component(input-row) {
  // Variables ----------------------
  $verticalSpacing: 1rem !default;

  // Component ----------------------
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  @include breakpoint(tablet) {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $verticalSpacing;
    margin-left: $verticalSpacing * -1;
    margin-right: $verticalSpacing * -1;
  }

  @include option(spacing-none) {
    @include breakpoint(tablet) {
      margin-bottom: 0;
    }
  }

  @include part(item) {
    flex: 1 1 100%;
    margin-bottom: $verticalSpacing;

    &:last-child {
      margin-bottom: 0;
    }

    @include breakpoint(tablet) {
      margin-bottom: 0;
      padding-left: $verticalSpacing;
      padding-right: $verticalSpacing;
    }
  }

  // Options - type ----------------------
  @include option(address) {
    @include breakpoint(tablet) {
      flex-wrap: nowrap;

      @include part(item) {
        &:nth-child(1) {
          flex-basis: 100%;
          margin-bottom: $verticalSpacing;
        }

        &:nth-child(2) {
          flex-basis: 50%;
        }

        &:nth-child(3) {
          flex-basis: 50%;
        }
      }
    }

    @include breakpoint(laptop) {
      @include part(item) {
        &:nth-child(1),
        &:nth-child(2) {
          flex-basis: 38%;
          margin-bottom: 0;
        }

        &:nth-child(3) {
          flex-basis: 24%;
        }
      }
    }
  }

  @include option(checkbox) {
    @include part(item) {
      margin-bottom: 0;
      margin-top: $verticalSpacing*0.5 * -1;

      &:first-child {
        margin-top: $verticalSpacing * -1;
      }
    }
  }

  @include option(twocol) {
    @include breakpoint(tablet) {
      flex-wrap: nowrap;

      @include part(item) {
        &:nth-child(1),
        &:nth-child(2) {
          flex-basis: 50%;
        }
      }
    }
  }

  @include option(fullname-title) {
    @include breakpoint(tablet) {
      flex-wrap: nowrap;

      @include part(item) {
        &:nth-child(1) {
          flex-basis: 24%;
        }

        &:nth-child(2),
        &:nth-child(3) {
          flex-basis: 38%;
        }
      }
    }
  }

  // Options - spacing ----------------------
  @include option(spacing-small) {
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
