@import "base/helpers";

@include component(error-handler) {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  color: white;
  background-color: #f33035;
  text-align: center;

  @include part(text) {
    margin: 0;
    padding-right: 0.4rem;
  }

  @include part(icon) {
    margin-right: 10px;
  }

  @include part(index) {
    margin-right: 5px;
  }

  @include part(query-error) {
    display: flex;
    align-items: center;

    p {
      margin-top: 0;
    }
  }

  @include part(subheading) {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }
}
