@import "base/helpers";

@include component(input-group-addon) {
  // Variables ----------------------
  $inputs_borderRadius: 4px !default;
  $inputs_borderRadius: 4px !default;
  $inputs_borderWidth: 1px !default;
  $inputs_borderColor: #eee !default;
  $inputs_color: #000 !default;
  $inputs_fontSize: 1rem !default;
  $clr_text: #333 !default;
  $clr_textSubdued: lighten($clr_text, 70%) !default;
  $verticalSpacing: 1rem !default;
  $addon_padding: 0.4rem !default;

  // Component ----------------------

  display: inline-table;
  border-collapse: separate;
  width: 100%;

  @include part(input) {
    width: 100%;
    input { border-radius: 0; }
  }

  @include part(addon) {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    padding: 0 $addon_padding;
    background-color: $clr_backgroundLightBlue;
    color: $clr_text;
    border: $inputs_borderWidth solid $inputs_borderColor;
    border-radius: $inputs_borderRadius;
    font-size: $inputs_fontSize;
    white-space: nowrap;

    &:first-child {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
