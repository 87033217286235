@import "base/helpers";

@include component(input) {
  // Variables ----------------------
  $clr_text: #333;
  $inputs_borderRadius: 4px !default;
  $inputs_borderWidth: 1px !default;
  $inputs_borderColor: #eee !default;
  $inputs_color: #000 !default;
  $inputs_fontSize: 1rem !default;
  $inputs_paddingVertical: 0.5rem !default;
  $inputs_paddingHorizontal: 1rem !default;

  // Component ----------------------

  position: relative;

  // Parts ----------------------

  @include part(successMsg) {
    position: absolute;
    right: 0.5em;
    top: 3px;
    font-size: 12px;
  }

  @include part(loadingIndicator) {
    position: absolute;
    top: 0;
    right: 0.5em;
  }

  // the input element
  @include part(element) {
    width: 100%;
    padding: $inputs_paddingVertical $inputs_paddingHorizontal;
    border: $inputs_borderWidth solid $inputs_borderColor;
    border-radius: $inputs_borderRadius;
    font-size: $inputs_fontSize;
    appearance: none;

    // State ----------------------

    &:focus {
      outline: none;
      border-color: rgba(31, 167, 219, 62%);
    }

    // Pseudos ----------------------

    &::input-placeholder {
      opacity: 100%;
      font-size: $inputs_fontSize;
      color: #c3c3c3;
    }

    // Options ----------------------

    @include option(isInvalid) {
      border-color: $inputs_errorColor;
    }

    @include option(small) {
      padding: $inputs_paddingVertical * 0.5 $inputs_paddingHorizontal * 0.5;
      font-size: $inputs_fontSize * 0.75;

      &::input-placeholder {
        opacity: 100%;
        font-size: $inputs_fontSize * 0.75;
        color: #c3c3c3;
      }
    }

    @include option(text-only) {
      background: transparent;
      border: none;
      color: $clr_text;
      padding: 0.5rem 0;
    }
  }

  @include part(infoMsg) {
    position: absolute;
    z-index: 10;
    top: -35px;
    left: -15px;
    visibility: hidden;
    width: 100%;
    padding: 5px 10px;
    white-space: nowrap;
    color: white;
    border-radius: 5px;
    background: rgba(#1fa7db, 0.9);
    font-size: 0.8em;
    white-space: normal;

    &::after {
      position: absolute;
      bottom: -10px;
      left: 50%;
      width: 0;
      height: 0;
      margin-left: -10px;
      content: "";
      border-top: 10px solid rgba(#1fa7db, 0.9);
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
    }
  }
}
