@import "base/helpers";

@include component(select) {
  // Variables ----------------------
  $inputs_borderRadius: 4px !default;
  $inputs_borderWidth: 1px !default;
  $inputs_borderColor: #eee !default;
  $inputs_color: #000 !default;
  $inputs_fontSize: 1rem !default;
  $inputs_paddingVertical: 0.5rem !default;
  $inputs_paddingHorizontal: 1rem !default;
  $greyLight: #e2e2e2;

  // Component ----------------------

  position: relative;
  display: block;

  // Parts ----------------------

  @include part(element) {
    appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    margin: 0;
    margin: 0;
    padding: $inputs_paddingVertical $inputs_paddingHorizontal;
    padding-left: $inputs_paddingHorizontal;
    padding-right: 32px;
    font-size: $inputs_fontSize;
    font-weight: 500;
    color: $inputs_color;
    background-color: transparent;
    background-image: none;
    border: $inputs_borderWidth solid $inputs_borderColor;
    border-radius: $inputs_borderRadius;
    cursor: pointer;

    // States ----------------------
    &:focus {
      outline: none;
    }

    &:disabled {
      cursor: default;
      background-color: $greyLight !important;
    }

    // Options ----------------------
    @include option(selected) {
      background: rgba(31, 167, 219, 62%);
      border-color: transparent;
      color: white;
    }

    @include option(isInvalid) {
      border: 1px solid rgba(255, 0, 0, 90%);
    }
  }

  // Pseudo Elements ----------------------

  @include option(selected) {
    &::after {
      border-top-color: white;
    }
  }

  &::after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.5em;
    vertical-align: middle;
    border-right: 0.3125em solid transparent;
    border-left: 0.3125em solid transparent;
    border-top: 0.4375em solid;
    position: absolute;
    right: $inputs_paddingHorizontal * 1;
    top: 50%;
    transform: translate(50%, -50%);
  }
}
