@import "base/helpers";

@include component(impact-selector) {
  // Variables ----------------------
  $clr_brandPrimary: #1fa8df !default;
  $font-family-primary: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif !default;
  $inputs_borderColor: lighten($clr_textSubdued, 30%) !default;
  $impact-selector-border-radius: 0.5rem;

  // Component ----------------------
  font-family: $font-family-primary;
  line-height: 1;

  // Parts ----------------------
  @include part(options) {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  @include part(option) {
    align-items: center;
    border: 1px solid $inputs_borderColor;
    display: flex;
    margin-top: -1px;
    padding: 0.75rem 1rem;
    width: 100%;

    &:first-child {
      border-top-left-radius: $impact-selector-border-radius;
      border-top-right-radius: $impact-selector-border-radius;
      margin-top: 0;
    }

    &:hover {
      cursor: pointer;
    }

    @include option(active) {
      background-color: $clr_brandPrimary;
      border-color: $clr_brandPrimary;
      color: #fff;
    }

    @include breakpoint(laptop) {
      padding: 1.125rem 1.5rem;
    }
  }

  @include part(option-amount) {
    @include typography-bold;

    display: block;
    flex: 0 1 auto;
    font-size: 1rem;
    padding-right: 0.75em;

    @include breakpoint(laptop) {
      font-size: 1.25rem;
      padding-right: 1.125rem;
    }
  }

  @include part(option-title) {
    display: block;
    flex: 1 1 auto;
    font-size: 0.875rem;
    line-height: 1.2;

    @include breakpoint(laptop) {
      font-size: 1rem;
    }
  }

  @include part(option-button) {
    display: block;
    width: 100%;
  }

  @include part(option-input) {
    @include visuallyHidden;
  }

  @include part(option-other) {
    align-items: center;
    display: flex;
    border: 1px solid $inputs_borderColor;
    border-bottom-left-radius: $impact-selector-border-radius;
    border-bottom-right-radius: $impact-selector-border-radius;
    justify-content: center;
    margin-top: -1px;
    padding: 0.75rem 1rem;
    position: relative;
    width: 100%;

    @include option(active) {
      background-color: $clr_brandPrimary;
      border-color: $clr_brandPrimary;
      color: #fff;
    }

    @include breakpoint(laptop) {
      padding: 1.125rem 1.5rem;
    }
  }

  @include part(input-currency) {
    @include typography-bold;

    padding-right: 0.25rem;

    @include breakpoint(laptop) {
      font-size: 1.25rem;
    }
  }

  @include part(input-other) {
    @include typography-bold;

    background: transparent;
    border: none;
    color: #888;
    width: 100%;

    @include breakpoint(laptop) {
      font-size: 1.25rem;
    }

    .impact-selector__option-other.--active & {
      color: #fff;
    }
  }
}
