@import "base/helpers";

@include component(currency-modal-selector) {
  // Variables ----------------------
  $clr_brandPrimary: #1fa8df !default;
  $clr_text: #333 !default;
  $clr_textSubdued: lighten($clr_text, 30%) !default;
  $inputs_borderColor: lighten($clr_textSubdued, 30%) !default;

  // Component ----------------------
  background-color: lighten($clr_brandPrimary, 45%);
  padding: 1rem;

  // Parts ----------------------
  @include part(line-item) {
    align-items: flex-start;
    display: flex;
    font-family: $font-family-primary;
    justify-content: space-between;
    line-height: 1.2;
    margin-top: 1rem;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    @include option(total) {
      @include part(line-item-value) {
        text-align: right;
      }
    }
  }

  .input-group {
    margin-bottom: 0;
  }

  @include part(line-item-label) {
    display: block;
    padding-right: 1rem;
  }

  @include part(line-item-value) {
    @include typography-bold;

    display: block;
  }

  @include part(line-item-currency) {
    font-weight: 400;
    padding-right: 0.5rem;
  }

  @include part(line-item-total) {
    font-size: 2rem;
  }

  @include part(line-item-suffix) {
    font-weight: 400;
    padding-left: 0.5rem;
  }
}
